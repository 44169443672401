import gql from "graphql-tag";

const TODO_ADD_SUBSCRIPTION = gql`
  subscription TaskAdded($taskId: ID!) {
    TaskAdded(taskId: $taskId) {
      id
      name
      list {
        id
        name
        status
        createdAt
        updatedAt
        completedBy {
          name
        }
        createdBy {
          name
        }
      }
    }
  }
`;

const TODO_EDIT_SUBSCRIPTION = gql`
  subscription UpdateTodo($taskIds: [ID]!) {
    UpdateTodo(taskIds: $taskIds) {
      id
      name
      __typename
      status
      completedBy {
        name
      }
    }
  }
`;

const TODO_DELETE_SUBSCRIPTION = gql`
  subscription DeleteTodo($taskIds: [ID]!) {
    DeleteTodo(taskIds: $taskIds) {
      id
      name
      updatedAt
    }
  }
`;

export { TODO_ADD_SUBSCRIPTION, TODO_EDIT_SUBSCRIPTION, TODO_DELETE_SUBSCRIPTION };
