import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Tabs, Icon } from "antd";
import { Query, Subscription } from "react-apollo";
import PersonalTab from "../TaskTab/PersonalTab";
import { TASKS_QUERY } from "../../utility/QUERY";
import { CurrentUser } from "../Shared/Login";
import CorporateTab from "../TaskTab/CorporateTab";

const TabPane = Tabs.TabPane;

const TaskTab = props => {
  const User = useContext(CurrentUser);
  const { tasks, corporateTasks } = props;

  return (
    <Tabs defaultActiveKey="1" onChange={() => {}}>
      <TabPane
        tab={
          <span>
            <Icon type="user" />
            Personal
          </span>
        }
        key="1"
      >
        <PersonalTab tasks={tasks} />
      </TabPane>
      <TabPane
        tab={
          <span>
            <Icon type="team" />
            Corporate
          </span>
        }
        key="2"
      >
        <CorporateTab tasks={corporateTasks} />
      </TabPane>
    </Tabs>
  );
};

TaskTab.propTypes = {};

export default TaskTab;
