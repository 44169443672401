import React, { useEffect } from "react";
import PropTypes from "prop-types";
import TimeAgo from "react-timeago";
import { List, Avatar, Icon } from "antd";
import { useStateValue } from "../../context/TabSubscription";
import sortBy from "lodash/sortBy";
const CorporateTab = props => {
  const [{ tasks }, dispatch] = useStateValue();
  useEffect(() => {
    dispatch({ type: "updateTasks", data: props.tasks });
  }, []);
  console.log("Hello tasks", tasks); // log is here
  return (
    <List
      itemLayout="vertical"
      dataSource={tasks}
      renderItem={item => {
        const latestTodo = sortBy(item.list, "updatedAt").slice(-1)[0];
        console.log("Hello latestTodo", latestTodo); // log is here
        return (
          <List.Item>
            <List.Item.Meta
              className="mb-0"
              avatar={
                latestTodo ? (
                  <Avatar style={{ backgroundColor: "#87d068" }}>
                    {latestTodo.completedBy ? latestTodo.completedBy.name[0] : latestTodo.createdBy.name[0]}
                  </Avatar>
                ) : (
                  <Avatar icon="user" />
                )
              }
              title={item.name}
              description={
                <>
                  {latestTodo ? (
                    <>
                      {latestTodo.completedBy ? (
                        <span>
                          {latestTodo.name} <b>Completed</b> by {latestTodo.completedBy.name}
                        </span>
                      ) : (
                        <span>
                          {latestTodo.name} <b>Created</b> by {latestTodo.createdBy.name}
                        </span>
                      )}
                      <div className="d-flex mt-1 align-items-center">
                        <Icon type="clock-circle" className="mr-2" />
                        <TimeAgo date={latestTodo.updatedAt} />
                      </div>
                    </>
                  ) : (
                    <>
                      <span>
                        Task <b>Created</b>
                      </span>
                      <div className="d-flex mt-1 align-items-center">
                        <Icon type="clock-circle" className="mr-2" />
                        <TimeAgo date={item.createdAt} />
                      </div>
                    </>
                  )}
                </>
              }
            />
          </List.Item>
        );
      }}
    />
  );
};

CorporateTab.propTypes = {};

export default CorporateTab;
