import React, { createContext, useReducer, useContext } from "react";
import { withApollo } from "react-apollo";
const TabSubscription = createContext(undefined);
const initialState = { tasks: [] };

let reducer = (state, action) => {
  switch (action.type) {
    case "reset":
      return initialState;
    case "updateTasks":
      return { tasks: action.data };
    case "updateSubscripTasks":
      const updatedTask = state.tasks.map(task => (task.id === action.data.id ? action.data : task));
      return { tasks: updatedTask };
    default:
      return state;
  }
};
const TabSubscriptionProvider = props => {
  // let [state, dispatch] = useReducer(reducer, initialState);
  // const [tasks, setTasks] = useState([]);
  //   useEffect(() => {});
  //   let { value } = props;
  return (
    <TabSubscription.Provider value={useReducer(reducer, initialState)}>
      {props.children}
    </TabSubscription.Provider>
  );
};

const TabSubscriptionConsumer = TabSubscription.Consumer;
const enhanceTabSub = withApollo(TabSubscriptionProvider);
const useStateValue = () => useContext(TabSubscription);
export { TabSubscription, useStateValue, TabSubscriptionConsumer, enhanceTabSub as TabSubscriptionProvider };
