import React, { useState, useContext, createContext } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { Query } from "react-apollo";
import { Modal } from "antd";
import { ME_QUERY } from "../../utility/QUERY";
import LoginIndex from "../Login/LoginIndex";

const CurrentUser = createContext(undefined);

const Login = props => {
  const [showModal, switchModal] = useState(true);
  return (
    <Query query={ME_QUERY}>
      {({ loading, error, data }) => {
        console.log("login", data);
        if (error) return "error";
        if (loading) return "loading";
        if (!data.me) {
          return (
            <Modal
              visible={showModal}
              title={null}
              closable={false}
              maskClosable={false}
              onCancel={() => switchModal(false)}
              footer={null}
            >
              <LoginIndex showModal={showModal} />
            </Modal>
          );
        }

        return <CurrentUser.Provider value={data.me}>{props.children}</CurrentUser.Provider>;
      }}
    </Query>
  );
};

Login.propTypes = {};

export default Login;
export { CurrentUser };
