import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Table, Menu, Dropdown, Button, Icon } from "antd";
import { withRouter } from "react-router";
import TimeAgo from "react-timeago";
import CompleteBttn from "./CompleteBttn";
import DeleteBttn from "./DeleteBttn";
import ActiveBttn from "./ActiveBttn";

const ListTable = props => {
  const columns = [
    {
      title: "Description",
      dataIndex: "name",
      width: "25%",
      render: (text, record) => {
        return (
          <>
            <h6>{text}</h6>
            <span className="text-muted">
              <TimeAgo date={record.createdAt} />
            </span>
          </>
        );
      }
    },

    {
      title: "Completed",
      width: "25%",
      dataIndex: "completedBy",
      render: (text, record) => {
        if (text) {
          return (
            <h5>
              <span className="badge badge-pill badge-success">{text.name}</span>
            </h5>
          );
        }
        return "n/a";
      }
    },
    {
      title: "Actions",
      width: "25%",
      dataIndex: "id",
      render: (text, record) => {
        return (
          <Dropdown
            placement="bottomCenter"
            overlay={
              <Menu>
                <Menu.Item key="1">
                  <CompleteBttn todoId={text} taskId={props.match.params.TaskId} />
                </Menu.Item>
                <Menu.Item key="2">
                  <ActiveBttn todoId={text} taskId={props.match.params.TaskId} />
                </Menu.Item>
                <Menu.Item key="3">
                  <DeleteBttn todoId={text} taskId={props.match.params.TaskId} />
                </Menu.Item>
              </Menu>
            }
          >
            <Button style={{ marginLeft: 8 }}>
              <Icon type="edit" />
            </Button>
          </Dropdown>
        );
      }
    }
  ];
  const { task, todoStatus, subscribeToNewTodos, subscribeToEditTodos, subscribeToDeleteTodos } = props;
  const list = todoStatus === "ALL" ? task.list : task.list.filter(L => L.status === todoStatus);
  useEffect(() => {
    subscribeToNewTodos();
    subscribeToDeleteTodos();
  }, []);
  return (
    <div className="col-12">
      <Table rowKey={record => record.id} columns={columns} dataSource={list} />
    </div>
  );
};

ListTable.propTypes = {};

export default withRouter(ListTable);
