import React, { Component } from "react";
import "./style/App.scss";
import { HashRouter as Router, Route, Switch, Link } from "react-router-dom";
import TaskIndex from "./containers/TaskIndex";
import { ApolloProvider } from "react-apollo";
import List from "./components/List/List";
import LoginIndex from "./components/Login/LoginIndex";
import { Layout, Button } from "antd";
import Panel from "./components/Shared/Panel";
import Login from "./components/Shared/Login";
import { TabSubscriptionProvider } from "./context/TabSubscription";
import { ApolloClient } from "apollo-client";
import { AuthCtxProvider } from "./context/AuthCtx";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import { onError } from "apollo-link-error";
import { ApolloLink, split } from "apollo-link";
import { WebSocketLink } from "apollo-link-ws";
import { getMainDefinition } from "apollo-utilities";
import { endPoint, ProdEndPoint, endWSPoint, ProdWSEndPoint } from "./utility/config";
const { Header, Content, Footer } = Layout;

console.log("Hello process.env.NODE_ENV", process.env.NODE_ENV); // log is here
const httpLink = new HttpLink({
  uri: process.env.NODE_ENV === "development" ? endPoint : ProdEndPoint,
  credentials: "include"
});

// Create a WebSocket link:
const wsLink = new WebSocketLink({
  uri: process.env.NODE_ENV === "development" ? endWSPoint : ProdWSEndPoint,
  options: {
    reconnect: true
  }
});

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = split(
  // split based on operation type
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === "OperationDefinition" && operation === "subscription";
  },
  wsLink,
  httpLink
);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache()
});

// import ApolloClient from "apollo-boost";
// const client = new ApolloClient({
//   uri: "http://localhost:5678",
//   request: operation => {

//     operation.setContext({
//       fetchOptions: {
//         credentials: "include"
//       }
//     });
//   }
// });

class App extends Component {
  render() {
    return (
      <ApolloProvider client={client}>
        <Router basename="/">
          <AuthCtxProvider>
            <Login>
              <TabSubscriptionProvider>
                <Layout className="app__container">
                  <Panel />
                  <Layout>
                    <Header style={{ background: "#fff", padding: 0 }}>
                      <Link className="ml-3" to="/">
                        <Button shape="circle" icon="left" />
                      </Link>
                    </Header>
                    <Content style={{ margin: "24px 16px 0" }}>
                      <Switch>
                        <Route exact path="/" component={TaskIndex} />
                        <Route path="/list/:TaskId" component={List} />
                        <Route path="/login" component={LoginIndex} />
                      </Switch>
                    </Content>
                    <Footer style={{ textAlign: "center" }}>Ant Design ©2018 Created by Ant UED</Footer>
                  </Layout>
                </Layout>
              </TabSubscriptionProvider>
            </Login>
          </AuthCtxProvider>
        </Router>
      </ApolloProvider>
    );
  }
}

export default App;
