import React from "react";
import PropTypes from "prop-types";
import TimeAgo from "react-timeago";
import { List, Avatar, Icon } from "antd";
const PersonalTab = props => {
  return (
    <List
      itemLayout="vertical"
      dataSource={props.tasks}
      renderItem={item => {
        const latestTodo = item.list.slice(-1)[0];
        return (
          <List.Item>
            <List.Item.Meta
              className="mb-0"
              avatar={
                latestTodo ? (
                  <Avatar style={{ backgroundColor: "#87d068" }}>
                    {latestTodo.completedBy ? latestTodo.completedBy.name[0] : latestTodo.createdBy.name[0]}
                  </Avatar>
                ) : (
                  <Avatar icon="user" />
                )
              }
              title={item.name}
              description={
                <>
                  {latestTodo ? (
                    <>
                      {latestTodo.completedBy ? (
                        <span>
                          {latestTodo.name} <b>Completed</b> by {latestTodo.completedBy.name}
                        </span>
                      ) : (
                        <span>
                          {latestTodo.name} <b>Created</b> by {latestTodo.createdBy.name}
                        </span>
                      )}
                      <div className="d-flex mt-1 align-items-center">
                        <Icon type="clock-circle" className="mr-2" />
                        <TimeAgo date={latestTodo.updatedAt} />
                      </div>
                    </>
                  ) : (
                    <>
                      <span>
                        Task <b>Created</b>
                      </span>
                      <div className="d-flex mt-1 align-items-center">
                        <Icon type="clock-circle" className="mr-2" />
                        <TimeAgo date={item.createdAt} />
                      </div>
                    </>
                  )}
                </>
              }
            />
          </List.Item>
        );
      }}
    />
  );
};

PersonalTab.propTypes = {};

export default PersonalTab;
