import React from "react";
import PropTypes from "prop-types";
import { Query } from "react-apollo";
import { Avatar, Tooltip } from "antd";
import { COOPERATORS_QUERY } from "../../utility/QUERY";

const Corporaters = props => {
  const { User } = props;
  return (
    <Query query={COOPERATORS_QUERY} variables={{ userId: User.id }}>
      {({ data, loading, error }) => {
        if (loading) return "Loading...";
        if (error) return "Error...";

        const { cooperators } = data;
        return (
          <div className="personal__corporatersList">
            {cooperators.map(u => (
              <Tooltip key={u.id} placement="top" title={u.name}>
                <Avatar size={30}>{u.name[0]}</Avatar>
              </Tooltip>
            ))}
          </div>
        );
      }}
    </Query>
  );
};

Corporaters.propTypes = {};

export default Corporaters;
