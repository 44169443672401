import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { Mutation } from "react-apollo";
import { ADD_TODO } from "../../utility/MUTATION";
import { LIST_QUERY } from "../../utility/QUERY";
import { Input, Button } from "antd";
import { CurrentUser } from "../Shared/Login";

const AddBttn = props => {
  const [todoContent, setContent] = useState("");
  const User = useContext(CurrentUser);
  return (
    <Mutation
      mutation={ADD_TODO}
      update={(cache, { data: { updateTask } }) => {
        const data = cache.readQuery({
          query: LIST_QUERY,
          variables: { id: props.taskId }
        });
        data.task.list = [...updateTask.list];
        cache.writeQuery({
          query: LIST_QUERY,
          variables: { id: props.taskId },
          data
        });
      }}
      optimisticResponse={{
        __typename: "Mutation",
        updateTask: {
          __typename: "Task",
          id: props.taskId,
          name: props.taskName,
          list: props.list
        }
      }}
    >
      {(updateTask, { loading }) => {
        return (
          <div className="creationInput">
            <Input
              onChange={e => setContent(e.target.value)}
              style={{ width: "200px" }}
              className="mr-3"
              value={todoContent}
            />
            <Button
              onClick={async e => {
                e.preventDefault();
                await updateTask({
                  variables: {
                    id: props.taskId,
                    todo: todoContent,
                    userId: User.id
                  }
                });
                setContent("");
              }}
            >
              Add
            </Button>
          </div>
        );
      }}
    </Mutation>
  );
};

AddBttn.propTypes = {};

export default AddBttn;
