import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Card, Icon, Avatar, Popover } from "antd";
import { Mutation } from "react-apollo";
import { DELETE_TASK } from "../../utility/MUTATION";
import { ME_QUERY, TASKS_QUERY } from "../../utility/QUERY";
const { Meta } = Card;

const TaskCard = props => {
  const { task } = props;
  const update = (
    cache,
    {
      data: {
        deleteTask: { id: taskId }
      }
    }
  ) => {
    const { me } = cache.readQuery({ query: ME_QUERY });
    let { tasks } = cache.readQuery({ query: TASKS_QUERY, variables: { userId: me.id } });
    tasks = tasks.filter(t => t.id !== taskId);
    const data = { ...me, tasks: me.tasks.filter(task => task.id !== taskId) };
    cache.writeQuery({
      query: ME_QUERY,
      data: { me: data }
    });

    cache.writeQuery({
      query: TASKS_QUERY,
      variables: { userId: me.id },
      data: { tasks }
    });
  };
  return (
    <Mutation
      mutation={DELETE_TASK}
      update={update}
      optimisticResponse={{
        __typename: "Mutation",
        deleteTask: {
          __typename: "Task",
          id: task.id
        }
      }}
    >
      {deleteTask => (
        <div className="col-md-4">
          <Card
            style={{ width: "100%" }}
            actions={[
              <Popover
                placement="topLeft"
                title={null}
                content={<div>{task.serialNum.serialNum}</div>}
                trigger="click"
              >
                <Icon type="team" />
              </Popover>,
              <Icon onClick={async () => await deleteTask({ variables: { taskId: task.id } })} type="edit" />
            ]}
          >
            <Link to={`/list/${task.id}`}>
              <Meta
                avatar={<h5>ge</h5>}
                title={task.name}
                description={
                  <div>
                    <Avatar style={{ color: "#f56a00", backgroundColor: "#fde3cf" }}>US</Avatar>
                    <Avatar style={{ color: "#f56a00", backgroundColor: "#fde3cf" }}>AK</Avatar>
                    <Avatar style={{ color: "#f56a00", backgroundColor: "#fde3cf" }}>DK</Avatar>
                  </div>
                }
              />
            </Link>
          </Card>
        </div>
      )}
    </Mutation>
  );
};

TaskCard.propTypes = {};
export default TaskCard;
