import React, { createContext, useReducer, useContext } from "react";

const AuthCtx = createContext([]);
const initialState = { user: { name: "", id: "", email: "" } };

let reducer = (state, action) => {
  switch (action.type) {
    case "userLogin":
      return { user: action.user };
    default:
      return state;
  }
};

const AuthCtxProvider = props => {
  return <AuthCtx.Provider value={useReducer(reducer, initialState)}>{props.children}</AuthCtx.Provider>;
};

const AuthCtxConsumer = AuthCtx.Consumer;
const AuthCtxAction = () => useContext(AuthCtx);

export { AuthCtx, AuthCtxProvider, AuthCtxConsumer, AuthCtxAction };
