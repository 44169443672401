import gql from "graphql-tag";

const ADD_TASK_MUTATION = gql`
  mutation ADD_TASK_MUTATION($name: String!) {
    createTask(data: { name: $name }) {
      id
      name
      serialNum {
        serialNum
      }
    }
  }
`;

const DELETE_TASK = gql`
  mutation($taskId: ID!) {
    deleteTask(where: { id: $taskId }) {
      id
    }
  }
`;

const LINK_TASK = gql`
  mutation($serialNum: ID!) {
    linkTask(serialNum: $serialNum) {
      tasks {
        id
      }
    }
  }
`;

const ADD_TODO = gql`
  mutation updateTask($id: ID!, $taskName: String, $todo: String!, $userId: ID!) {
    updateTask(
      where: { id: $id }
      data: { name: $taskName, list: { create: { name: $todo, createdBy: { connect: { id: $userId } } } } }
    ) {
      id
      name
      list {
        id
        name
        status
        createdAt
        updatedAt
        completedBy {
          name
        }
        createdBy {
          name
        }
      }
    }
  }
`;

const UPDATE_TODO = gql`
  mutation updateTodo($id: ID!, $taskId: ID!, $completedBy: ID!, $status: TodoStatus!) {
    updateTodo(
      where: { id: $id }
      data: { completedBy: { connect: { id: $completedBy } }, status: $status }
      taskId: $taskId
    ) {
      id
      name
      updatedAt
      completedBy {
        name
      }
      status
    }
  }
`;

const DELETE_TODO = gql`
  mutation deleteTodo($id: ID!, $taskId: ID!) {
    deleteTodo(where: { id: $id }, taskId: $taskId) {
      id
      name
      completedBy {
        name
      }
      status
      updatedAt
    }
  }
`;
export { ADD_TASK_MUTATION, DELETE_TASK, LINK_TASK, ADD_TODO, UPDATE_TODO, DELETE_TODO };
