import React from "react";
import PropTypes from "prop-types";
import { Query } from "react-apollo";
import TaskCard from "../components/Task/TaskCard";
import { ME_QUERY } from "../utility/QUERY";
const TaskIndex = React.memo(props => {
  return (
    <Query query={ME_QUERY}>
      {({ loading, error, data }) => {
        if (loading) return <p>Loading...</p>;
        if (error) return <p>Error :(</p>;
        return (
          <div className="container p-3">
            <div className="row">
              {data.me.tasks.map(task => (
                <TaskCard key={task.id} task={task} />
              ))}
            </div>
          </div>
        );
      }}
    </Query>
  );
});

TaskIndex.propTypes = {};
export default TaskIndex;
