import React from "react";
import { Form, Icon, Input, Button, Checkbox } from "antd";
import gql from "graphql-tag";
import { Mutation } from "react-apollo";

const ADD_USER_MUTATION = gql`
  mutation createUser($username: String!, $email: String!, $password: String!) {
    createUser(data: { name: $username, email: $email, password: $password }) {
      id
      name
    }
  }
`;

const SignUp = props => {
  const { getFieldDecorator } = props.form;
  const handleSubmit = async (e, mutation) => {
    e.preventDefault();
    props.form.validateFields(async (err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);
        const data = { ...values };
        console.log("Hello data", data); // log is here
        const res = await mutation({ variables: data });
        console.log("Hello res", res); // log is here
      }
    });
  };

  return (
    <Mutation mutation={ADD_USER_MUTATION}>
      {(createUser, { loading }) => {
        return (
          <Form onSubmit={e => handleSubmit(e, createUser)} className="login-form">
            <Form.Item>
              {getFieldDecorator("username", {
                rules: [{ required: true, message: "Please input your username!" }]
              })(
                <Input
                  prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
                  placeholder="Username"
                />
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator("email", {
                rules: [{ required: true, message: "Please input your username!" }]
              })(
                <Input
                  prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
                  placeholder="E-Mail"
                />
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator("password", {
                rules: [{ required: true, message: "Please input your Password!" }]
              })(
                <Input
                  prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
                  type="password"
                  placeholder="Password"
                />
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator("comfirm password", {
                rules: [{ required: true, message: "Please input your Password!" }]
              })(
                <Input
                  prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
                  type="password"
                  placeholder="Enter Password Again"
                />
              )}
            </Form.Item>
            <Form.Item>
              <div className="d-flex flex-column ">
                <Button type="primary" htmlType="submit" className="login-form-button">
                  Sign Up
                </Button>
                <a className="login-form-forgot" href="">
                  Forgot password
                </a>
              </div>
            </Form.Item>
          </Form>
        );
      }}
    </Mutation>
  );
};

export default Form.create()(SignUp);
