import React, { useContext } from "react";
import { Button } from "antd";
import { Mutation } from "react-apollo";
import { LIST_QUERY } from "../../utility/QUERY";
import { UPDATE_TODO } from "../../utility/MUTATION";
import { CurrentUser } from "../Shared/Login";

const ActiveBttn = props => {
  const User = useContext(CurrentUser);
  return (
    <Mutation
      mutation={UPDATE_TODO}
      refetchQueries={[{ query: LIST_QUERY, variables: { id: props.taskId } }]}
    >
      {(updateTodo, { loading }) => {
        return (
          <Button
            icon="check"
            onClick={async () => {
              await updateTodo({
                variables: {
                  id: props.todoId,
                  taskId: props.taskId,
                  completedBy: User.id,
                  status: "ACTIVE"
                }
              });
            }}
          >
            ACTIVE
          </Button>
        );
      }}
    </Mutation>
  );
};

export default ActiveBttn;
