import React from "react";
import { Button } from "antd";
import { Mutation } from "react-apollo";
import { LIST_QUERY } from "../../utility/QUERY";
import { DELETE_TODO } from "../../utility/MUTATION";

const MutationBttn = props => {
  return (
    <Mutation
      mutation={DELETE_TODO}
      optimisticResponse={{
        __typename: "Mutation",
        deleteTodo: {
          __typename: "Todo",
          id: props.todoId
        }
      }}
      update={(cache, { data: { deleteTodo } }) => {
        const { task } = cache.readQuery({ query: LIST_QUERY, variables: { id: props.taskId } });
        task.list = task.list.filter(t => t.id !== deleteTodo.id);
        cache.writeQuery({ query: LIST_QUERY, variables: { id: props.taskId }, data: { task } });
      }}
    >
      {(deleteTodo, { loading }) => {
        return (
          <Button
            icon="delete"
            style={{ width: "100%" }}
            className="d-flex justify-content-start"
            onClick={async () => {
              await deleteTodo({
                variables: {
                  id: props.todoId,
                  taskId: props.taskId
                }
              });
            }}
          >
            Delete
          </Button>
        );
      }}
    </Mutation>
  );
};

export default MutationBttn;
