import React, { useContext, useCallback } from "react";
import { Form, Icon, Input, Button } from "antd";
import gql from "graphql-tag";
import { Link } from "react-router-dom";
import { ME_QUERY } from "../../utility/QUERY";
import { Mutation } from "react-apollo";
import { AuthCtxAction } from "../../context/AuthCtx";

const LOGIN_USER_MUTATION = gql`
  mutation signin($email: String!, $password: String!) {
    signin(email: $email, password: $password) {
      id
      name
    }
  }
`;

const SignIn = props => {
  const { getFieldDecorator } = props.form;
  const [_, dispatch] = AuthCtxAction();
  const handleType = useCallback((e, mutation) => handleSubmit(e, mutation));
  const handleSubmit = async (e, mutation) => {
    e.preventDefault();
    props.form.validateFields(async (err, values) => {
      if (!err) {
        const data = { ...values };
        const {
          data: { signin }
        } = await mutation({ variables: data });
        dispatch({
          type: "userLogin",
          user: {
            name: signin.name,
            id: signin.id,
            email: signin.email
          }
        });
        console.log("Hello res", signin); // log is here
      }
    });
  };
  return (
    <Mutation mutation={LOGIN_USER_MUTATION} refetchQueries={[{ query: ME_QUERY }]}>
      {(signin, { loading }) => {
        return (
          <Form onSubmit={e => handleType(e, signin)} className="login-form">
            <Form.Item>
              {getFieldDecorator("email", {
                rules: [{ required: true, message: "Please input your username!" }]
              })(
                <Input
                  prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
                  placeholder="E-Mail"
                />
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator("password", {
                rules: [{ required: true, message: "Please input your Password!" }]
              })(
                <Input
                  prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
                  type="password"
                  placeholder="Password"
                />
              )}
            </Form.Item>
            <Form.Item>
              <div className="d-flex flex-column ">
                <Button type="primary" htmlType="submit" className="login-form-button">
                  Log in
                </Button>
                <a className="login-form-forgot" href="">
                  Forgot password
                </a>
              </div>
            </Form.Item>
          </Form>
        );
      }}
    </Mutation>
  );
};

export default React.memo(Form.create()(SignIn));
