import React, { useState, useContext, useEffect } from "react";
import { Layout, Avatar, Modal, Button, notification } from "antd";
import { CurrentUser } from "./Login";
import { Query, Subscription } from "react-apollo";
import TaskAdd from "../Task/TaskAdd";
import TaskTab from "./TaskTab";
import Corporaters from "./Corporaters";
import isEmpty from "lodash/isEmpty";
import {
  TODO_ADD_SUBSCRIPTION,
  TODO_EDIT_SUBSCRIPTION,
  TODO_DELETE_SUBSCRIPTION
} from "../../utility/SUBSCRIPTION";
import { TASKS_QUERY } from "../../utility/QUERY";
const { Sider } = Layout;

const Panel = props => {
  const [showTaskModal, switchModal] = useState(false);
  const [taskNotice, switchNotice] = useState({});
  const User = useContext(CurrentUser);
  const popUp = (type = "success") => {
    const message =
      type === "success"
        ? `Todo item ${taskNotice.name} has been created by ${taskNotice.createdBy.name}`
        : taskNotice.message;
    notification[type]({
      message: "Notification Title",
      description: message,
      onClick: () => {
        console.log("Notification Clicked!");
      }
    });
  };
  useEffect(() => {
    !isEmpty(taskNotice) && popUp(taskNotice.type);
  });

  return (
    <Query query={TASKS_QUERY} variables={{ userId: User.id }}>
      {({ data, loading, error }) => {
        if (error) return "error";
        if (loading) return "loading";
        const { tasks } = data;
        const corporateTasks = tasks.filter(t => t.cooperators.length > 1);
        return (
          <Sider
            breakpoint="lg"
            collapsedWidth="0"
            onBreakpoint={broken => {}}
            theme="light"
            width="380"
            className="shadow"
          >
            <div className="personal p-3">
              <div className="personal__info d-flex mt-5 mb-4">
                <div className="personal__avatar">
                  <Avatar size={64} icon="user" />
                </div>
                <div className="personal__name d-flex align-items-center p-3">{User.name}</div>
              </div>
              <hr />
              <div className="personal__tasks mt-5 w-100 position-static">
                <h5>Tasks</h5>
                <div className="d-flex justify-content-end">
                  <Button onClick={() => switchModal(true)} shape="circle" icon="plus" />
                </div>
                <TaskTab tasks={tasks} corporateTasks={corporateTasks} />
              </div>
              <hr />
              <div className="personal__corporaters mt-5">
                <h5>Corporaters</h5>
                <Corporaters User={User} />
              </div>
              <Modal
                visible={showTaskModal}
                title={null}
                closable={false}
                onCancel={() => switchModal(false)}
                footer={null}
              >
                <TaskAdd switchModal={switchModal} />
              </Modal>
            </div>
            <Subscription subscription={TODO_ADD_SUBSCRIPTION} variables={{ taskId: User.id }}>
              {({ data, loading }) => {
                if (data && data.TaskAdded) {
                  const popUpNotice = data.TaskAdded.list.slice(-1)[0];
                  switchNotice(popUpNotice);
                }
                return null;
              }}
            </Subscription>
            <Subscription
              subscription={TODO_EDIT_SUBSCRIPTION}
              variables={{ taskIds: corporateTasks.map(task => task.id) }}
            >
              {({ data, loading }) => {
                if (data && data.UpdateTodo) {
                  const message = `Todo item ${data.UpdateTodo.name} has been updated by ${
                    data.UpdateTodo.completedBy.name
                  }`;
                  switchNotice({ message, type: "info" });
                }
                return null;
              }}
            </Subscription>
            <Subscription
              subscription={TODO_DELETE_SUBSCRIPTION}
              variables={{ taskIds: corporateTasks.map(task => task.id) }}
            >
              {({ data, loading }) => {
                if (data && data.DeleteTodo) {
                  const message = `Todo item ${data.DeleteTodo.name} was delete at ${
                    data.DeleteTodo.updatedAt
                  }`;
                  switchNotice({ message, type: "warning" });
                }
                return null;
              }}
            </Subscription>
          </Sider>
        );
      }}
    </Query>
  );
};

export default Panel;
