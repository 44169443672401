import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { Radio, notification } from "antd";
import { withRouter } from "react-router";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { LIST_QUERY } from "../../utility/QUERY";
import AddBttn from "./AddBttn";
import ListTable from "./ListTable";
import { CurrentUser } from "../Shared/Login";
import {
  TODO_ADD_SUBSCRIPTION,
  TODO_EDIT_SUBSCRIPTION,
  TODO_DELETE_SUBSCRIPTION
} from "../../utility/SUBSCRIPTION";
import { useStateValue } from "../../context/TabSubscription";
const List = props => {
  const User = useContext(CurrentUser);
  const [todoStatus, setStatus] = useState("ALL");
  const [_, dispatch] = useStateValue();

  return (
    <Query
      query={LIST_QUERY}
      variables={{
        id: props.match.params.TaskId
      }}
    >
      {({ loading, error, data, subscribeToMore }) => {
        if (loading) return <p>Loading...</p>;
        if (error) return <p>Error :(</p>;
        const { task } = data;

        return (
          <div className="container bg-white p-3">
            <div className="row mb-3">
              <div className="col-12 d-flex justify-content-between">
                <AddBttn taskId={props.match.params.TaskId} taskName={task.name} list={task.list} />
                <Radio.Group value={todoStatus} onChange={e => setStatus(e.target.value)}>
                  <Radio.Button value="ALL">ALL</Radio.Button>
                  <Radio.Button value="ACTIVE">ACTIVE</Radio.Button>
                  <Radio.Button value="COMPLETED">COMPLETED</Radio.Button>
                </Radio.Group>
              </div>
            </div>
            <div className="row no-gutters">
              <ListTable
                task={task}
                todoStatus={todoStatus}
                subscribeToNewTodos={() =>
                  subscribeToMore({
                    document: TODO_ADD_SUBSCRIPTION,
                    variables: { taskId: User.id },
                    updateQuery: (prev, { subscriptionData }) => {
                      dispatch({ type: "updateSubscripTasks", data: prev.task });
                      return {
                        ...prev
                      };
                    }
                  })
                }
                subscribeToEditTodos={() =>
                  subscribeToMore({
                    document: TODO_EDIT_SUBSCRIPTION,
                    variables: { taskIds: [props.match.params.TaskId] },
                    updateQuery: (prev, { subscriptionData }) => {
                      console.log("Hello prev.task", prev.task); // log is here
                      console.log("Hello subscriptionData", subscriptionData); // log is here
                      dispatch({ type: "updateSubscripTasks", data: prev.task });
                      return prev;
                    }
                  })
                }
                subscribeToDeleteTodos={() =>
                  subscribeToMore({
                    document: TODO_DELETE_SUBSCRIPTION,
                    variables: { taskIds: [props.match.params.TaskId] },
                    updateQuery: (prev, { subscriptionData }) => {
                      if (subscriptionData.data.DeleteTodo) {
                        const deleteTodoID = subscriptionData.data.DeleteTodo.id;
                        prev.task.list = prev.task.list.filter(list => list.id !== deleteTodoID);
                      }
                      return prev;
                    }
                  })
                }
              />
            </div>
          </div>
        );
      }}
    </Query>
  );
};

List.propTypes = {};
export default withRouter(List);
