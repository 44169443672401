import React, { useState } from "react";
import { Tabs } from "antd";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
const TabPane = Tabs.TabPane;

const LoginIndex = () => {
  const [activeTab, setTab] = useState("1");
  return (
    <div className="container p-3">
      <Tabs defaultActiveKey={activeTab} onChange={tabIndex => setTab(tabIndex)}>
        <TabPane tab="SIGN IN" key="1">
          <SignIn />
        </TabPane>
        <TabPane tab="SIGN UP" key="2">
          <SignUp />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default LoginIndex;
