import gql from "graphql-tag";

const ME_QUERY = gql`
  query ME_QUERY {
    me {
      id
      name
      tasks {
        id
        name
        serialNum {
          serialNum
        }
      }
    }
  }
`;

const SINGLE_TASK_QUERY = gql`
  query SINGLE_TASK_QUERY($id: ID!) {
    task(where: { id: $id }) {
      id
      name
      serialNum {
        serialNum
      }
      list {
        id
        name
      }
    }
  }
`;

const LIST_QUERY = gql`
  query LIST_QUERY($id: ID!) {
    task(where: { id: $id }) {
      id
      name
      createdAt
      list {
        id
        name
        status
        createdAt
        updatedAt
        completedBy {
          name
        }
        createdBy {
          name
        }
      }
    }
  }
`;

const TASKS_QUERY = gql`
  query TASKS_QUERY($userId: ID!) {
    tasks(where: { cooperators_some: { id: $userId } }) {
      id
      name
      createdAt
      cooperators {
        id
        name
      }
      list {
        id
        name
        createdAt
        updatedAt
        completedBy {
          name
        }
        createdBy {
          name
        }
      }
    }
  }
`;

const COOPERATORS_QUERY = gql`
  query COOPERATORS_QUERY($userId: ID!) {
    cooperators(userId: $userId) {
      id
      name
    }
  }
`;

export { ME_QUERY, SINGLE_TASK_QUERY, LIST_QUERY, TASKS_QUERY, COOPERATORS_QUERY };
