import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { Form, Button, Icon, Input, Divider } from "antd";
import { Mutation } from "react-apollo";
import { ME_QUERY, TASKS_QUERY } from "../../utility/QUERY";
import { LINK_TASK, ADD_TASK_MUTATION } from "../../utility/MUTATION";
import { CurrentUser } from "../Shared/Login";

const TaskAdd = props => {
  const [serialNum, setSerialNum] = useState("");
  const [disableBttn, setDisableBttn] = useState(false);
  const User = useContext(CurrentUser);
  const { getFieldDecorator, setFieldsValue, validateFields } = props.form;
  const handleSubmit = (e, createTask) => {
    e.preventDefault();
    setDisableBttn(true);
    validateFields(async (err, values) => {
      try {
        if (!err) {
          await createTask({ variables: { name: values.taskName } });
          setFieldsValue({ taskName: "" });
          props.switchModal(false);
          setDisableBttn(false);
        }
      } catch (error) {
        console.log("createTask error", error); // log is here
      }
    });
  };
  return (
    <Form onSubmit={handleSubmit} className="login-form">
      <Mutation
        mutation={ADD_TASK_MUTATION}
        refetchQueries={[{ query: TASKS_QUERY, variables: { userId: User.id } }]}
        update={(cache, { data: { createTask } }) => {
          const { me } = cache.readQuery({ query: ME_QUERY });
          const data = { ...me, tasks: me.tasks.concat([createTask]) };
          cache.writeQuery({
            query: ME_QUERY,
            data: { me: data }
          });
        }}
      >
        {(createTask, { loading }) => {
          return (
            <>
              <Form.Item>
                <h6>Create Task</h6>
                {getFieldDecorator("taskName", {
                  rules: [{ required: true, message: "Please input your Task Name!" }]
                })(
                  <Input
                    size="large"
                    prefix={<Icon type="book" style={{ color: "rgba(0,0,0,.25)" }} />}
                    placeholder="Task Name"
                  />
                )}
              </Form.Item>
              <Form.Item>
                <Button
                  disabled={disableBttn}
                  onClick={e => handleSubmit(e, createTask)}
                  htmlType="submit"
                  type="primary"
                  block
                >
                  CREATE
                </Button>
              </Form.Item>
            </>
          );
        }}
      </Mutation>

      <Divider> or </Divider>
      <Mutation mutation={LINK_TASK} refetchQueries={[{ query: ME_QUERY }]}>
        {(linkTask, { loading }) => {
          return (
            <>
              <Form.Item>
                <h6>Join a Task</h6>
                <Input
                  size="large"
                  prefix={<Icon type="book" style={{ color: "rgba(0,0,0,.25)" }} />}
                  placeholder="Task ID"
                  value={serialNum}
                  onChange={e => setSerialNum(e.target.value)}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  block
                  onClick={async () => {
                    const res = await linkTask({ variables: { serialNum } });
                    console.log("Hello res", res); // log is here
                  }}
                >
                  JOIN
                </Button>
              </Form.Item>
            </>
          );
        }}
      </Mutation>
    </Form>
  );
};

TaskAdd.propTypes = {};

export default Form.create()(TaskAdd);
